import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import api from "../components/api";
import { NotificationRule } from "../api";
import AddNotificationRuleFormDialog from "../components/FormDialog/AddNotificationRule";
import EditNotificationRule from "../components/FormDialog/EditNotificationRule";
import GenericTable from "../components/Table/Table";
import { useParams } from "react-router-dom";

const columnConfig = [
  { id: "user_role", label: "Role", minWidth: 100 },
  { id: "user_profession", label: "Profession", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "inform_day", label: "Inform Day", minWidth: 100 },
  { id: "order_types", label: "Order Types", minWidth: 100 }, 
  { id: "message", label: "Message", minWidth: 200 },
  { id: "actions", label: "Actions", minWidth: 100 },
];

const NotificationRules: React.FC = () => {
  const [notificationRules, setNotificationRules] = useState<
    NotificationRule[]
  >([]);
  const [openAddRuleDialog, setOpenAddRuleDialog] = useState<boolean>(false);
  const [openEditRuleDialog, setOpenEditRuleDialog] = useState<boolean>(false);
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>(null);
  const { status_id } = useParams<{ status_id?: string }>();
  const [statusList, setStatusList] = useState<Record<string, string>>({});

  const getStatusList = async () => {
    try {
      const resp = await api.status.getStatusStatusGet();
      const statusMap = resp.data.contents.reduce(
        (
          map: Record<string, string>,
          status: { status_id: string; status: string },
        ) => {
          map[status.status_id] = status.status;
          return map;
        },
        {},
      );
      setStatusList(statusMap);
    } catch (error) {
      console.error("Failed to fetch status list:", error);
      setStatusList({});
    }
  };

  const getNotificationRules = async () => {
    try {
      const resp =
        await api.notificationRules.getNotificationRulesNotificationRulesGet();

      const allRules = resp.data.contents.map((rule: NotificationRule) => ({
        notification_rule_id: rule.notification_rule_id,
        user_role: rule.user_role,
        user_professions: rule.user_professions,
        user_profession: rule.user_professions.join(", "),
        status: statusList[rule.status_id] || rule.status_id,
        status_id: rule.status_id,
        inform_day: rule.inform_day,
        order_types: rule.order_types.join(", "), 
        message: rule.message,
        self_link: rule.self_link,
        kind: rule.kind,
      }));

      const filteredRules = status_id
        ? allRules.filter((rule) => rule.status_id === status_id)
        : allRules;

      setNotificationRules(filteredRules as unknown as NotificationRule[]);
      return filteredRules;
    } catch (error) {
      console.error("Failed to fetch notification rules:", error);
      setNotificationRules([]);
      return [];
    }
  };

  const handleDeleteRule = async (ruleId: string) => {
    try {
      await api.notificationRules.deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete(ruleId);
      getNotificationRules();
    } catch (error) {
      console.error("Failed to delete notification rule:", error);
    }
  };

  useEffect(() => {
    getStatusList();
  }, []);

  useEffect(() => {
    if (Object.keys(statusList).length > 0) {
      getNotificationRules();
    }
  }, [statusList, status_id]);

  const getActionsButton = (row: NotificationRule) => {
    return (
      <>
        <IconButton
          color="primary"
          onClick={() => {
            setSelectedRuleId(row.notification_rule_id);
            setOpenEditRuleDialog(true);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="secondary"
          onClick={() => handleDeleteRule(row.notification_rule_id)}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="h5">
          {status_id
            ? `Notification Rules for Status: ${statusList[status_id] || status_id}`
            : "Notification Rules"}
        </Typography>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenAddRuleDialog(true)}
          >
            Add Notification Rule
          </Button>
        </Box>
      </Box>
      <Box>
        <GenericTable
          columnsConfig={columnConfig}
          tableType="notification_rule"
          rows={notificationRules}
          getRows={getNotificationRules}
          setRows={setNotificationRules}
          getActionsButton={getActionsButton}
        />
      </Box>
      <AddNotificationRuleFormDialog
        open={openAddRuleDialog}
        handleClose={() => setOpenAddRuleDialog(false)}
        setNotificationRules={setNotificationRules}
        refreshData={getNotificationRules}
      />
      {selectedRuleId && (
        <EditNotificationRule
          open={openEditRuleDialog}
          handleClose={() => setOpenEditRuleDialog(false)}
          ruleId={selectedRuleId}
          refreshData={getNotificationRules}
        />
      )}
    </Box>
  );
};

export default NotificationRules;
