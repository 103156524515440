import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import api from "../api";
import { NotificationRule, UserRole, UserProfession, OrderType } from "../../api/api";
import FormField from "./FormField";

interface EditNotificationRuleProps {
  open: boolean;
  handleClose: () => void;
  ruleId: string;
  refreshData: () => void;
}

const EditNotificationRule: React.FC<EditNotificationRuleProps> = ({
  open,
  handleClose,
  ruleId,
  refreshData,
}) => {
  const [formData, setFormData] = useState({
    user_role: "admin" as UserRole,
    user_professions: [] as UserProfession[],
    status_id: "",
    inform_day: 0,
    order_types: [] as OrderType[],
    message: "",
  });
  const [statuses, setStatuses] = useState<Array<{ value: string; label: string }>>([]);

  useEffect(() => {
    const fetchRule = async () => {
      try {
        const resp = await api.notificationRules.getNotificationRulesRuleIdNotificationRulesRuleIdGet(
          ruleId
        );

        setFormData({
          user_role: resp.data.user_role as UserRole,
          user_professions: resp.data.user_professions.map((profession: string) => profession as UserProfession),
          status_id: resp.data.status_id,
          inform_day: resp.data.inform_day,
          order_types: resp.data.order_types.map((type: string) => type as OrderType),
          message: resp.data.message,
        });
      } catch (error) {
        console.error("Failed to fetch notification rule:", error);
      }
    };

    if (open) {
      fetchRule();
    }
  }, [open, ruleId]);

  useEffect(() => {
    api.status.getStatusStatusGet().then((res) => {
      setStatuses(res.data.contents.map(status => ({
        value: status.status_id,
        label: status.status
      })));
    });
  }, []);

  const handleSubmit = async () => {
    try {
      await api.notificationRules.patchNotificationRulesRuleIdNotificationRulesRuleIdPatch(ruleId, {
        ...formData,
      });
      refreshData();
      handleClose();
    } catch (error) {
      console.error("Failed to update notification rule:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Notification Rule</DialogTitle>
      <DialogContent>
        <FormField
          type="select"
          name="user_role"
          label="User Role"
          value={formData.user_role}
          onChange={(value) => setFormData({ ...formData, user_role: value })}
          options={Object.values(UserRole).map(role => ({ value: role, label: role }))}
          required
        />
        <FormField
          type="select"
          name="user_professions"
          label="User Professions"
          value={formData.user_professions}
          onChange={(value) => setFormData({ ...formData, user_professions: value })}
          options={Object.values(UserProfession).map(prof => ({ value: prof, label: prof }))}
          multiple
        />
        <FormField
          type="select"
          name="status"
          label="Status"
          value={formData.status_id}
          onChange={(value) => setFormData({ ...formData, status_id: value })}
          options={statuses}
          required
        />
        <FormField
          type="number"
          name="inform_day"
          label="Inform Day (Days Before)"
          value={formData.inform_day}
          onChange={(value) => setFormData({ ...formData, inform_day: Number(value) })}
          required
        />
        <FormField
          type="select"
          name="order_types"
          label="Order Types"
          value={formData.order_types}
          onChange={(value) => setFormData({ ...formData, order_types: value })}
          options={Object.values(OrderType).map(type => ({ value: type, label: type }))}
          multiple
          required
        />
        <FormField
          type="text"
          name="message"
          label="Message"
          value={formData.message}
          onChange={(value) => setFormData({ ...formData, message: value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNotificationRule;
