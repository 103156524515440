import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { AxiosResponse } from "axios";
import { UserCreate, User, UserProfession } from "../../api/api";
import api from "../api";
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";

interface AddFormDialogProps {
  open: boolean;
  handleClose: () => void;
  userType: "client" | "staff" | "admin";
  addUser: (user: User) => void;
}

const AddFormDialog: React.FC<AddFormDialogProps> = ({
  open,
  handleClose,
  userType,
  addUser,
}) => {
  const [userCreate, setUserCreate] = React.useState<UserCreate>({
    first_name: "",
    last_name: "",
    role: userType,
    extras: {
      address: "",
      pregnancy_due_date: null,
      client_birthday: null,
      baby_birth_date: null,
      profession: undefined,
    },
    user_email: "",
    phone_number: "",
    password: "",
    wechat_account: "",
  });

  const [professionError, setProfessionError] = React.useState(false);
  const [source, setSource] = React.useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (
      name === "pregnancy_due_date" ||
      name === "client_birthday" ||
      name === "baby_birth_date"
    ) {
      setUserCreate((prevState) => ({
        ...prevState,
        extras: {
          ...prevState.extras,
          [name]: value ? dayjs(value) : null,
        },
      }));
    } else if (
      name === "address" ||
      name === "profession" ||
      name === "comment"
    ) {
      setUserCreate((prevState) => ({
        ...prevState,
        extras: {
          ...prevState.extras,
          [name]: value,
        },
      }));
    } else {
      setUserCreate((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date: Dayjs | null, name: string) => {
    setUserCreate((prevState) => ({
      ...prevState,
      extras: {
        ...prevState.extras,
        [name]: date,
      },
    }));
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    name: string,
  ) => {
    const { value } = event.target;
    if (name === "profession") {
      setUserCreate((prevState) => ({
        ...prevState,
        extras: {
          ...prevState.extras,
          profession: value as UserProfession,
        },
      }));
    } else if (name === "source") {
      setSource(value);
      setUserCreate((prevState) => ({
        ...prevState,
        extras: {
          ...prevState.extras,
          source: value,
        },
      }));
    } else {
      setUserCreate((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (userType === "staff" && !userCreate.extras?.profession) {
      setProfessionError(true);
      return;
    }

    if (userType === "client") {
      userCreate.role = "client";
    } else if (userType === "staff") {
      userCreate.role = "staff";
    }

    try {
      const response: AxiosResponse<User> =
        await api.root.postRegisterRegisterPost(userCreate);

      const data = {
        ...response.data,
        name: response.data.first_name + " " + response.data.last_name,
      };
      addUser(data);
      handleClose();
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>
        Add{" "}
        {userType === "client"
          ? "Client"
          : userType === "staff"
            ? "Staff Member"
            : "Admin"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the necessary information to add a{" "}
          {userType === "client"
            ? "client"
            : userType === "staff"
              ? "staff member"
              : "admin"}
          :
        </DialogContentText>

        <TextField
          autoFocus
          margin="dense"
          id="first_name"
          name="first_name"
          label="First Name"
          fullWidth
          variant="standard"
          required
          value={userCreate.first_name}
          onChange={handleInputChange}
        />

        <TextField
          margin="dense"
          id="last_name"
          name="last_name"
          label="Last Name"
          fullWidth
          variant="standard"
          value={userCreate.last_name}
          onChange={handleInputChange}
          required
        />

        <TextField
          margin="dense"
          id="user_email"
          name="user_email"
          label="Email"
          fullWidth
          variant="standard"
          required
          value={userCreate.user_email}
          onChange={handleInputChange}
        />

        <TextField
          margin="dense"
          id="phone_number"
          name="phone_number"
          label="Phone Number"
          fullWidth
          variant="standard"
          required
          value={userCreate.phone_number}
          onChange={handleInputChange}
        />

        <TextField
          margin="dense"
          id="password"
          name="password"
          label="Password"
          type="password"
          fullWidth
          variant="standard"
          required
          value={userCreate.password}
          onChange={handleInputChange}
        />

        <TextField
          margin="dense"
          id="wechat_account"
          name="wechat_account"
          label="WeChat Account"
          fullWidth
          variant="standard"
          required
          value={userCreate.wechat_account}
          onChange={handleInputChange}
        />

        <TextField
          margin="dense"
          id="address"
          name="address"
          label="Address"
          fullWidth
          variant="standard"
          value={userCreate.extras?.address || ""}
          onChange={handleInputChange}
        />
        {userType === "client" && (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box mb={1}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Pregnancy due date"
                    onChange={(date) =>
                      handleDateChange(date, "pregnancy_due_date")
                    }
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </DemoContainer>
              </Box>
              <Box mb={1}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Client birthday"
                    onChange={(date) =>
                      handleDateChange(date, "client_birthday")
                    }
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </DemoContainer>
              </Box>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Baby Birthdate"
                  onChange={(date) => handleDateChange(date, "baby_birth_date")}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <FormControl
              fullWidth
              margin="dense"
              required={userType === "client"}
              error={professionError}
              sx={{ mb: 1 }}
            >
              <InputLabel id="source-label">Source</InputLabel>
              <Select
                labelId="source-label"
                id="source"
                name="source"
                value={source}
                onChange={(e) => handleSelectChange(e, "source")}
                label="Source"
              >
                <MenuItem value="xiaohongshu">XiaoHongshu</MenuItem>
                <MenuItem value="instagram">Instagram</MenuItem>
                <MenuItem value="wechat">Wechat</MenuItem>
                <MenuItem value="facebook">Facebook</MenuItem>
                <MenuItem value="referrals">Referrals</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              {professionError && (
                <FormHelperText>
                  Profession is required for staff role.
                </FormHelperText>
              )}
            </FormControl>

            {(source === "referrals" || source === "other") && (
              <TextField
                margin="dense"
                id="comment"
                name="comment"
                label="Comment"
                fullWidth
                variant="standard"
                value={userCreate.extras?.comment || ""}
                onChange={handleInputChange}
              />
            )}
          </>
        )}

        {userType === "staff" && (
          <FormControl
            fullWidth
            margin="dense"
            required={userType === "staff"}
            error={professionError}
          >
            <InputLabel id="profession-label">Profession</InputLabel>
            <Select
              labelId="profession-label"
              id="profession"
              name="profession"
              value={userCreate.extras?.profession ?? ""}
              onChange={(e) => handleSelectChange(e, "profession")}
              label="Profession"
            >
              <MenuItem value="photographer">Photographer</MenuItem>
              <MenuItem value="videographer">Videographer</MenuItem>
              <MenuItem value="makeup_artist">Makeup artist</MenuItem>
              <MenuItem value="postproduction_editor">
                Post-production editor
              </MenuItem>
              <MenuItem value="wedding_makeup_artist">
                Wedding Makeup Artist
              </MenuItem>
              <MenuItem value="wedding_photographer">
                Wedding photographer
              </MenuItem>
              <MenuItem value="wedding_videographer">
                Wedding videographer
              </MenuItem>
              <MenuItem value="wedding_day_editor_m">
                Wedding Day Editor m
              </MenuItem>
              <MenuItem value="Wedding_day_fast_photo_developer_i">
                Wedding Day Fast Photo Developer i
              </MenuItem>
              <MenuItem value="postcolorist">Post-colorist</MenuItem>
              <MenuItem value="posteditor">Post-editor</MenuItem>
            </Select>
            {professionError && (
              <FormHelperText>
                Profession is required for staff role.
              </FormHelperText>
            )}
          </FormControl>
        )}

        {userType === "admin" && (
          <FormControl fullWidth margin="dense" required>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              name="role"
              value={userCreate.role}
              onChange={(e) => handleSelectChange(e, "role")}
              label="Role"
              required
            >
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFormDialog;
